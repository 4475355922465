import { IoChevronBack } from 'react-icons/io5';
import { FaRocket } from 'react-icons/fa';
import { motion } from 'framer-motion';
import BackButton from "../../components/ui/BackButton";

export default function MarketplaceHome() {
    return (
        <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-col px-6 py-12 lg:p-16">
            {/* Back Button */}
            <div className="mb-6">
                <BackButton/>
            </div>

            {/* Title & Subtitle */}
            <div className="text-center max-w-2xl mx-auto">
                <h1 className="text-4xl lg:text-5xl font-bold text-white leading-tight">
                    🚀 The <span className="text-blue-400">Appchain Marketplace</span> is Coming Soon!
                </h1>
                <p className="mt-4 text-lg text-white/80">
                    Discover, buy, and sell cutting-edge Appchain templates directly on Quasm.
                    Stay ahead and get ready for the future of blockchain innovation.
                </p>
            </div>

            {/* Coming Soon Section */}
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
                className="flex flex-col items-center justify-center mt-12"
            >
                <div className="bg-white text-black p-8 rounded-lg shadow-lg text-center max-w-md">
                    <motion.div
                        animate={{ rotate: [0, 15, -15, 0] }}
                        transition={{ repeat: Infinity, duration: 2 }}
                        className="flex items-center justify-center mb-4"
                    >
                        <FaRocket className="text-5xl text-blue-500" />
                    </motion.div>

                    <h2 className="text-2xl font-bold mb-2">Launching Soon!</h2>
                    <p className="text-lg mb-4">
                        We're crafting an incredible marketplace experience for you.
                        Be the first to explore it!
                    </p>

                    <button className="mt-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white py-2 px-6 rounded-full hover:scale-105 transform transition duration-300 shadow-lg"
                    onClick={() => window.location.href = process.env.REACT_APP_BOOK_CALL_LINK}>
                        Get Notified
                    </button>
                </div>
            </motion.div>
        </div>
    );
}
